/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, Text, Title, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Galerie"} description={"Fotky z realizace, Frýdlant a Brušperk"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"kontakt-0"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/29621235dc954f6a9c9d6aacbebcc835_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/29621235dc954f6a9c9d6aacbebcc835_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/29621235dc954f6a9c9d6aacbebcc835_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/29621235dc954f6a9c9d6aacbebcc835_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/29621235dc954f6a9c9d6aacbebcc835_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/29621235dc954f6a9c9d6aacbebcc835_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Výsadba Frýdlant n.O.<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/05bca07c37f7448891034b978a18c0a4_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Jaro po výsadbě<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/449e9769976c49adbc1226618aa27eb0_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/449e9769976c49adbc1226618aa27eb0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/449e9769976c49adbc1226618aa27eb0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/449e9769976c49adbc1226618aa27eb0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/449e9769976c49adbc1226618aa27eb0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/449e9769976c49adbc1226618aa27eb0_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Jaro ...<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/709b0ede1e114e7c9b409bf28174edf0_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/709b0ede1e114e7c9b409bf28174edf0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/709b0ede1e114e7c9b409bf28174edf0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/709b0ede1e114e7c9b409bf28174edf0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/709b0ede1e114e7c9b409bf28174edf0_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Jaro .."}>
              </Subtitle>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/27072bc8558043d2b5c939abebf1718f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":495}} srcSet={"https://cdn.swbpg.com/t/19015/27072bc8558043d2b5c939abebf1718f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/27072bc8558043d2b5c939abebf1718f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/27072bc8558043d2b5c939abebf1718f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/27072bc8558043d2b5c939abebf1718f_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Po roce ...<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Pokud v prvním roce po výsadbě máme plody, redukujeme je na minimum nebo odstraníme, vyplatí se to v dalších letech ...<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":211}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/babe6d59627c4c03af641a08253642ae_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/babe6d59627c4c03af641a08253642ae_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/babe6d59627c4c03af641a08253642ae_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/babe6d59627c4c03af641a08253642ae_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/babe6d59627c4c03af641a08253642ae_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Po dvou a více ...<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/6bc86330953c4301b2babb34b7258eb6_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/6bc86330953c4301b2babb34b7258eb6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/6bc86330953c4301b2babb34b7258eb6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/6bc86330953c4301b2babb34b7258eb6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/6bc86330953c4301b2babb34b7258eb6_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

              <Subtitle className="subtitle-box" content={"Po dvou a více ...<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Nektarinky jsou určitě velkým zpestřením zahrady"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/7929b50cc0294d6e90f868e6792a5264_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/7929b50cc0294d6e90f868e6792a5264_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/7929b50cc0294d6e90f868e6792a5264_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/7929b50cc0294d6e90f868e6792a5264_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/7929b50cc0294d6e90f868e6792a5264_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/7929b50cc0294d6e90f868e6792a5264_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Ani meruňky nemusejí být problémem ...<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/74b325309c3e4d549326b5f30f4b9153_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":495}} srcSet={"https://cdn.swbpg.com/t/19015/74b325309c3e4d549326b5f30f4b9153_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/74b325309c3e4d549326b5f30f4b9153_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/74b325309c3e4d549326b5f30f4b9153_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/74b325309c3e4d549326b5f30f4b9153_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Asijská hrušen<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Ve sloupové formě se hodí i do malé zahrady a má mnoho výhod<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":211}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/813bd8add2be49be9e37f7d0d0f94210_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/813bd8add2be49be9e37f7d0d0f94210_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/813bd8add2be49be9e37f7d0d0f94210_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/813bd8add2be49be9e37f7d0d0f94210_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/813bd8add2be49be9e37f7d0d0f94210_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Maliníky<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Maliníky mohou vyrůstat až do dvou metrů a mít dvě úrody ročně<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/70bbc31e17ad4b4a95a21367426d7a6d_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/70bbc31e17ad4b4a95a21367426d7a6d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/70bbc31e17ad4b4a95a21367426d7a6d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/70bbc31e17ad4b4a95a21367426d7a6d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/70bbc31e17ad4b4a95a21367426d7a6d_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

              <Subtitle className="subtitle-box" content={"&nbsp;...<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/f9c0d858ed0b449992ebb0598681edcd_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/f9c0d858ed0b449992ebb0598681edcd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/f9c0d858ed0b449992ebb0598681edcd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/f9c0d858ed0b449992ebb0598681edcd_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Moruše<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Moruše v převislé formě pesně plní roli užitečného i okrasného stromu<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/53b8810f270b4997b8763668e59c4ca8_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":495}} srcSet={"https://cdn.swbpg.com/t/19015/53b8810f270b4997b8763668e59c4ca8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/53b8810f270b4997b8763668e59c4ca8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/53b8810f270b4997b8763668e59c4ca8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/53b8810f270b4997b8763668e59c4ca8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/53b8810f270b4997b8763668e59c4ca8_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Realizace Brušperk<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Zídka umožní pěstování i náročnějších odrůd<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":211}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/2176f0253cd5423ea075914b7c1132b4_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/2176f0253cd5423ea075914b7c1132b4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/2176f0253cd5423ea075914b7c1132b4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/2176f0253cd5423ea075914b7c1132b4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/2176f0253cd5423ea075914b7c1132b4_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Fíkovník<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Fíkovníku se zde nelépe daří ve skleníku<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/1b87699cf6984a24b5c08dba80dacefa_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":199}} srcSet={"https://cdn.swbpg.com/t/19015/1b87699cf6984a24b5c08dba80dacefa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/1b87699cf6984a24b5c08dba80dacefa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/1b87699cf6984a24b5c08dba80dacefa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/1b87699cf6984a24b5c08dba80dacefa_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

              <Subtitle className="subtitle-box" content={"Višně<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Višně jsou vítaným pikantním ovocem, odrůdy jsou od sladších po kyselejší<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/2ff7f35a9e2c438caf85ba6305d72c6d_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":449}} srcSet={"https://cdn.swbpg.com/t/19015/2ff7f35a9e2c438caf85ba6305d72c6d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/2ff7f35a9e2c438caf85ba6305d72c6d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/2ff7f35a9e2c438caf85ba6305d72c6d_s=860x_.jpg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Angrešt<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Angreštu, rybízu a malinám se zde daří. <br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/5d84890e2e634b3d9d4576ee2f79b554_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":263}} srcSet={"https://cdn.swbpg.com/t/19015/5d84890e2e634b3d9d4576ee2f79b554_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/5d84890e2e634b3d9d4576ee2f79b554_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/5d84890e2e634b3d9d4576ee2f79b554_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/5d84890e2e634b3d9d4576ee2f79b554_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Třešeň"}>
              </Subtitle>

              <Text className="text-box" content={"Zejména na Srdcovku jen tak něco nemá ..<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":211}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/d6d47fb53c24494b9e34079f3a47d2ba_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/19015/d6d47fb53c24494b9e34079f3a47d2ba_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/d6d47fb53c24494b9e34079f3a47d2ba_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/d6d47fb53c24494b9e34079f3a47d2ba_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/d6d47fb53c24494b9e34079f3a47d2ba_s=1400x_.jpg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Broskvoň"}>
              </Subtitle>

              <Text className="text-box" content={"Broskvoním se zde celkem daří, podmínkou je dobré stanoviště<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/2a4f2dd846c5498cb30ad34887103ef9_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":283}} srcSet={"https://cdn.swbpg.com/t/19015/2a4f2dd846c5498cb30ad34887103ef9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/2a4f2dd846c5498cb30ad34887103ef9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/2a4f2dd846c5498cb30ad34887103ef9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/2a4f2dd846c5498cb30ad34887103ef9_s=1400x_.jpg 1400w"} lightbox={true}>
              </Image>

              <Subtitle className="subtitle-box" content={"Jabloně<br>"}>
              </Subtitle>

              <Text className="text-box" content={"I letní odrůda může být krásná a s červeným líčkem<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image src={"https://cdn.swbpg.com/t/19015/ac29534fca9145728b52d8efe0d0abf7_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":449}} srcSet={"https://cdn.swbpg.com/t/19015/ac29534fca9145728b52d8efe0d0abf7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/ac29534fca9145728b52d8efe0d0abf7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/ac29534fca9145728b52d8efe0d0abf7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/ac29534fca9145728b52d8efe0d0abf7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/ac29534fca9145728b52d8efe0d0abf7_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Rakytník<br>"}>
              </Subtitle>

              <Text className="text-box" content={"Rakytník s trny či bez trní je vždy vitamínovou bombou.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Výsadba, poradenství<br>&amp; řez ovocných stromků<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Hlavní 68, Frýdlant nad Ostravicí</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"Užitečná zahrada<br>Hlavní 68<br>739 11 Frýdlant nad Ostravicí<br>+420 777 707 360<br>info@uzitecnazahrada.cz"}>
              </Text>

              <Text className="text-box" content={"<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1nsg8tv pb--60 pt--60" name={"kontakt"} layout={"l1"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19015/7fc0da7336b944ec960d9f5aea826da3_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--10 pt--10">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Máte zájem o některou službu?</span>"}>
              </Title>

              <Button className="btn-box" use={"page"} href={"/kontakt"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}